module.exports = {
  siteTitle: '3D Hearter', // <title>
  manifestName: '3D Hearter',
  manifestShortName: '3D Hearter', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#353238',
  manifestThemeColor: '#353238',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  pathPrefix: '', // This path is subpath of your hosting https://domain/portfolio
  heading: '3D HEARTER',
  subHeading: 'Study and explore human heart virtually',
  // social
  socialLinks: [
    // {
    //   style: 'brands',
    //   icon: 'fa-instagram',
    //   name: 'Instagram',
    //   url: 'https://www.instagram.com/ad_furdova/',
    // },
    {
      style: 'solid',
      icon: 'fa-envelope',
      name: 'Email',
      url: 'mailto:ad.furdova@gmail.com',
    },
    {
      style: 'brands',
      icon: 'fa-linkedin',
      name: 'LinkedIn',
      url: 'https://linkedin.com/in/adfurdova',
    },

    {
      style: 'brands',
      icon: 'fa-researchgate',
      name: 'ResearchGate',
      url: 'https://www.researchgate.net/profile/Ad_Furdova2',
    },
  ],
};
