import React from 'react';
import config from '../../config';
export default function Footer() {
  return (
    <footer id="footer">
      <ul className="icons">
        {config.socialLinks.map(social => {
          const { style, icon, name, url } = social;
          return (
            <li key={url}>
              <a href={url} className={`icon ${style} ${icon}`}>
                <span className="label">{name}</span>
              </a>
            </li>
          );
        })}
      </ul>
      <ul className="copyright">
        <li>Copyright &copy; 2020 by Ad. Furdova, MD</li>
        <br />
        <p>
          {' '}
          <sub>
            <a href="/terms-and-conditions">Terms and Conditions</a>
          </sub>
          <br />
          <sub>
            Design: <a href="http://html5up.net">HTML5 UP</a>
          </sub>
        </p>
      </ul>
    </footer>
  );
}
